/** @jsxImportSource @emotion/react */
import Footer from "../../../components/shared/Footer/Footer";
import PrevNextCaseStudy from "../../../components/shared/PrevNextCaseStudy/PrevNextCaseStudy";
import { PageProps } from "../../../components/utils/PageProps";
import CardCarousel from "./components/CardsCarousel/CardCarousel";
import ComplicatedUniverse from "./components/ComplicatedUniverse/ComplicatedUniverse";
import EvolutionDevelopment from "./components/EvolutionDevelopment/EvolutionDevelopment";
import Header from "./components/Header/Header";
import Puzzle from "./components/Puzzle section/Puzzle";
import Quotes from "./components/Quotes section/Quotes";
import Results from "./components/Results/Results";
import ScrollingImages from "./components/ScrollingImages/ScrollingImages";
import WorkshopSlider from "./components/WorkshopSlider/WorkshopSlider";

const Isatra = (props: PageProps) => {
  return (
    <>
      <Header />
      <ComplicatedUniverse />
      <ScrollingImages />
      <WorkshopSlider />
      <CardCarousel />
      <Puzzle />
      <Quotes />
      <EvolutionDevelopment />
      <Results />
      <PrevNextCaseStudy />
      <Footer />
    </>
  );
};

export default Isatra;
