/** @jsxImportSource @emotion/react */
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import MabiParallax from "../../../../../components/MabiloftSmoothScrollInterpolator";

const PicturesSection = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box
      sx={{
        margin: "auto",
        padding: { md: "120px 0px", xs: "80px 0px" },
        paddingBottom: "88px",
      }}
    >
      <Box
        sx={{
          margin: "auto",
          width: "fit-content",
          position: "relative",
          padding: { lg: "90px", md: "44px", xs: 0 },
        }}
      >
        <MabiParallax
          id="isatra-header"
          tweens={
            isOverMd
              ? [
                  {
                    selector: ".headerMain",
                    delay: -1,
                    from: {
                      y: 200,
                      opacity: 0,
                    },
                    to: {
                      y: 0,
                      opacity: 1,
                    },
                  },
                  {
                    selector: ".headerPic1",
                    delay: 0.4,
                    from: {
                      filter: "blur(10px)",
                      opacity: 0,
                      y: 100,
                    },
                    to: {
                      filter: "blur(0rem)",
                      y: 0,
                      opacity: 1,
                    },
                  },
                  {
                    selector: ".headerPic2",
                    delay: 0.45,

                    from: {
                      filter: "blur(10px)",
                      opacity: 0,

                      y: 50,
                    },
                    to: {
                      filter: "blur(0rem)",
                      opacity: 1,

                      y: 0,
                    },
                  },
                  {
                    selector: ".headerPic3",
                    delay: 0.35,

                    from: {
                      filter: "blur(10px)",
                      opacity: 0,

                      y: 100,
                    },
                    to: {
                      filter: "blur(0rem)",
                      opacity: 1,

                      y: 0,
                    },
                  },
                  {
                    selector: ".headerPic4",
                    delay: 0.3,

                    from: {
                      filter: "blur(10px)",
                      opacity: 0,

                      y: 50,
                    },
                    to: {
                      filter: "blur(0rem)",
                      opacity: 1,

                      y: 0,
                    },
                  },
                ]
              : []
          }
        >
          <Box
            sx={{
              maxWidth: "900px",
              marginX: "auto",
              borderRadius: "40px",
              overflow: "hidden",
            }}
            className="headerMain"
          >
            <StaticImage alt="" src="../../images/headerMain.png" />
          </Box>
          {isOverMd && (
            <Box
              sx={{
                width: "50%",
                maxWidth: "430px",
                position: "absolute",
                top: "15%",
                right: 0,
              }}
              className="headerPic1"
            >
              <StaticImage
                alt=""
                src="../../images/headerCard1.png"
                css={{ width: "100%" }}
              />
            </Box>
          )}
          {isOverMd && (
            <Box
              sx={{
                width: "50%",
                maxWidth: "450px",
                position: "absolute",
                bottom: "20%",
                left: 0,
              }}
              className="headerPic2"
            >
              <StaticImage
                alt=""
                src="../../images/headerCard2.png"
                css={{ width: "100%" }}
              />
            </Box>
          )}
          {isOverMd && (
            <Box
              sx={{
                width: "50%",
                maxWidth: "290px",
                position: "absolute",
                top: "0%",
                left: 0,
              }}
              className="headerPic3"
            >
              <StaticImage
                alt=""
                src="../../images/headerCard3.png"
                css={{ width: "100%" }}
              />
            </Box>
          )}
          {isOverMd && (
            <Box
              sx={{
                width: "50%",
                maxWidth: "300px",
                position: "absolute",
                top: "-100px",
                right: "-20px",
              }}
              className="headerPic4"
            >
              <StaticImage
                alt=""
                src="../../images/headerCard4.png"
                css={{ width: "100%" }}
              />
            </Box>
          )}
        </MabiParallax>
      </Box>
    </Box>
  );
};

export default PicturesSection;
